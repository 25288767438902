import React from "react";

import { Button } from "@mui/material";

import { CartAlertModal } from "./cart-alert-modal";
import { CartHeader } from "./components/cart-header";
import { CartListItem } from "./components/cart-list-item";

import { formatter } from "../../../../Modules/Common/commonUtils";

import "./cart-view.style.css";
import { useCartViewState } from "./cart-view.state";
import { DriverTip } from "@components/organisms/driver-tip";
import { Tableware } from "@components/organisms/tableware";
import { ConvenienceFee } from "@components/organisms/convenience-fee";
import {
  CATERER_DRIVER_TIP_TYPE,
  ORDER_DELIVERY_MODE,
  ORDER_TIP_TYPE,
} from "@constants/order.constants";
import { ICartItem, IOrderPayload } from "@services/redux/order";
import { ICatererDetailsResponse } from "typings/caterer.api";
import { IUserData } from "typings/user.api";

interface CartViewProps {
  customerId: string;
  cartData: ICartItem[];
  catererData: ICatererDetailsResponse;
  headCount: number;
  address: string;
  date: Date | null;
  zip: string;
  order: IOrderPayload;
  user: IUserData;
  isAvailable: "date" | "address" | true;
  onAddressFocus(): void;
  onCloseCart(): void;
  onCartItemClick(item: ICartItem, index: number): void;
  onUpdateOrder(params: Partial<IOrderPayload>): void;
  onUpdateTotalCost(cost: number): void;
}

export function CartView({
  order,
  headCount,
  customerId,
  user,
  address,
  zip,
  isAvailable,
  cartData,
  date,
  onCloseCart,
  onCartItemClick,
  onUpdateOrder,
  onUpdateTotalCost,
  ...props
}: CartViewProps) {
  const catererData = props.catererData?.getCatererDetails;
  const isTaxExempt = user?.data?.currentUserDetails?.taxExempt || false;

  if (!catererData) {
    return null;
  }

  const { salesTax, tip, cost, tableware, cart, convenienceFee, alertModal, onSubmit } = useCartViewState({
    caterer: {
      zip: catererData.catererZipCode,
      city: catererData.catererCity,
      state: catererData.catererStateShort,
      id: catererData._id,
      minOrderAmount: catererData.minOrderAmt,
      isTipMandatory: catererData.driverTipFlag === CATERER_DRIVER_TIP_TYPE.mandatory,
      isMandatoryTipPercentage: catererData.driverTipType === ORDER_TIP_TYPE.percentage,
      mandatoryTipValue: catererData.driverTip,
    },
    order,
    customerId,
    user,
    tableware: {
      isCharged: catererData.tablewareCharged,
      cost: catererData.tablewareAmount,
    },
    headCount,
    isAvailable,
    onUpdateOrder,
    onUpdateTotalCost,
  });

  return (
    <div className="cart-view">
      <CartAlertModal
        onClose={alertModal.onClose}
        onContinue={alertModal.onContinue}
        type={alertModal.type as "hidden" | "warning" | "error"}
        title={alertModal.title}
        description={alertModal.description}
      />
      <CartHeader cartItemsQuantity={cart.cartItemsQuantity} onClose={onCloseCart} />
      <div className="cart-view-list">
        {cartData.map((item, index) => (
          <CartListItem
            key={item._id || `cart-item-${index}`}
            item={item}
            index={index}
            onItemSelect={onCartItemClick}
            onItemRemove={cart.onDeleteCartItem}
          />
        ))}
      </div>

      <div className="other-charges">
        <div className="other-charges-item">
          <label>Food & Beverage</label>
          <b>{formatter.format(cost.cartCost)}</b>
        </div>

        <Tableware
          cost={tableware.value}
          isCharged={catererData.tablewareCharged}
          isRemoved={tableware.isTablewareRemoved}
          onAdd={tableware.onAddTableware}
          onRemove={tableware.onRemoveTableware}
        />

        <div className="other-charges-item">
          <label>Delivery Fee</label>
          <b>{formatter.format(cost.deliveryFee)}</b>
        </div>

        <ConvenienceFee total={convenienceFee.value} description={convenienceFee.description} />

        <div className="other-charges-item">
          <label>{ isTaxExempt ? 'Sales Tax' : `${salesTax.percentage}% Sales Tax`}</label>
          <b>{ isTaxExempt ? 'Tax Free' : formatter.format(salesTax.value)}</b>
        </div>
      </div>

      {order.deliveryMode !== ORDER_DELIVERY_MODE.pickup && (
        <DriverTip
          isTipMandatory={catererData?.driverTipFlag === CATERER_DRIVER_TIP_TYPE.mandatory}
          activeDriverTip={
            catererData?.driverTipFlag === CATERER_DRIVER_TIP_TYPE.mandatory
              ? tip.mandatoryTip
              : tip.value
          }
          percentageTip={tip.percentageTip}
          onChangeCustomTip={tip.onChangeCustomTip}
          onChangePercentageTip={tip.onChangePercentageTip}
        />
      )}

      <div className="cart-total common-cart-viewbox">
        <label>Total</label>
        <b>{formatter.format(cost.totalCost)}</b>
      </div>

      {headCount && (
        <div className="art-price-perhead common-cart-viewbox">
          <label>Price Per Head</label>
          <b>{`${formatter.format(cost.totalCost / headCount)}/person`}</b>
        </div>
      )}

      {catererData.caterCash && (
        <div className="cart-catercash">
          <img src="../assets/images/icon-catercash.svg" className="img-fluid" alt="Catercash" />
          CaterCash Earned &nbsp;
          <b>{`${formatter.format((cost.cartCost * catererData.caterCash) / 100)}`}</b>
        </div>
      )}

      {!zip ? (
        <Button
          className="confirm-address-warning"
          variant="outlined"
          color="primary"
          onClick={props.onAddressFocus}>
          <img src="../assets/images/icon-info.svg" className="img-fluid" alt="Info" />
          <span>Verify your full address to confirm availability.</span>
        </Button>
      ) : null}

      <Button
        className="review-cart-btn"
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSubmit}>
        <img
          src="../assets/images/shopping-cart-white.svg"
          className="img-fluid"
          alt="Shopping Cart"
        />
        Review Order
      </Button>

      <div className="cart-review-text text-center">
        <p>
          Total price inclusive of delivery, tax, and fees. No immediate charge will be applied.
        </p>
        <p>Promo codes can be applied at the end of the checkout process.</p>
      </div>
    </div>
  );
}
