import React, { useState } from "react";
import { TextField, Button, FormHelperText } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { useCookies } from 'react-cookie';
import { UPDATE_USER } from "../../Graphql/mutations";
import { useReactiveVar } from "@apollo/client";
import {
  userDeatils,
  toggleSnackbar
} from "../../ReactiveVariables/index";

export default function AccountDetails({ id, userType, setActiveStep, currentDetails }) {
  const { token } = useParams();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cookies, setCookie] = useCookies(['user', 'image', 'userType']);
  const user = useReactiveVar(userDeatils);

  // Validation schema
  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    phoneNumber: yup.string().required("Phone number is required").min(10, "The phone number must be 10 digits."),
    referralCode: yup.string().optional(),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6) + "-" + newNum.slice(6, 10);
    }
    return x;
  };

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    onCompleted: (res) => {
      // Move to the next step after successful update
      setCookie('user', res?.updateUserIdentity?.name, {
        path: '/',
        domain: '.caterplace.com',
      });
      setCookie('userType', 'Customer', {
        path: '/',
        domain: '.caterplace.com',
      });
      const userData = user?.data?.createUserIdentity;
      const updatedUser = {
        createUserIdentity: {
          ...userData,
          ...res?.updateUserIdentity,
          fullName: res?.updateUserIdentity?.name,
        }
      }
      userDeatils({
        data: updatedUser
      });
      setActiveStep((prevStep) => prevStep + 1);
    },
    onError: (err) => {
      toggleSnackbar({
        status: true,
        message: 'Error updating user',
        variant: "error",
      });
    }
  });

  const onSubmit = (data) => {
    // Format the form data for the mutation
    const formattedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      name: data.firstName.concat(" ", data.lastName),
      phoneNumber: phoneNumber,
      referralCode: data.referralCode || "",
      _id: id,
    };

    // Call the mutation with the user id and data
    updateUser({
      variables: {
        id: id,
        data: formattedData,
      },
    });
  };

  return (
    <div className="create-step-form">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col-md-6 textBox">
            <TextField
              id="createCustomerFirstName"
              label="FIRST NAME*"
              autoFocus
              variant="outlined"
              className="textField allFeild"
              {...register("firstName")}
            />
            {errors.firstName && <span className="error-msg">{errors.firstName.message}</span>}
          </div>
          <div className="col-md-6 textBox">
            <TextField
              id="createCustomerLastName"
              label="LAST NAME*"
              variant="outlined"
              className="textField allFeild"
              {...register("lastName")}
            />
            {errors.lastName && <span className="error-msg">{errors.lastName.message}</span>}
          </div>

          <div className="col-md-6">
            <TextField
              id="createAccountPhoneNumber"
              label={userType === "Customer" ? "PHONE*" : "BUSINESS CONTACT NUMBER*"}
              variant="outlined"
              autoComplete="new-Phone"
              InputProps={{
                autoComplete: 'new-username',
              }}
              className={`textField allFeild ${userType === "Customer" ? '' : 'mb-0'}`}
              value={phoneNumber}
              onChange={(e) => {
                if (!isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) && (e.target.value.replace(/[-,(,), ]+/g, "").length <= 10)) {
                  register("phoneNumber").onChange({ target: { value: e.target.value, name: "phoneNumber" } })
                  setPhoneNumber(phoneNumberFormat(e.target.value))
                }
              }}
            />
            {!errors.phoneNumber && userType !== 'Customer' &&
              <FormHelperText className="mt-0">We will only use your number for order updates.</FormHelperText>
            }
            {errors.phoneNumber && <span className="error-msg">{errors.phoneNumber.message}</span>}
          </div>
          <div className="col-md-6">
            <TextField
              id="createCustomerReferralCode"
              label="REFERRAL CODE (IF APPLICABLE)"
              variant="outlined"
              className="textField allFeild"
              defaultValue={token}
              {...register("referralCode")}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? "Updating..." : "Next"}
          </Button>
        </div>
        {error && <p className="error-msg">Failed to update user: {error.message}</p>}
      </form>
    </div>
  );
}
