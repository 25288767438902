// this page is for resetting the password
import React from 'react';

import { useState } from "react";
import { useNavigate,useParams} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client/react";
import {
  Button,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import LoginHeader from "./LoginHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from '@mui/material';
import * as yup from "yup";
import { toggleSnackbar } from "../../ReactiveVariables/index";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {RESET_PASSWORD} from "../../Graphql/mutations"

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { id } = useParams();
  const schema = yup.object().shape({
    password: yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Password and confirm password do not match"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  // toggling between text/ password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
   // toggling between text/ password
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
 const navigate = useNavigate()
  const [resetPassword,{ loading}] = useMutation(RESET_PASSWORD)

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // resetting password mutation
  function onSubmit(params) {
    try {
        resetPassword({
          variables: { resetPasswordToken: id, newPassword: params.password },
        })
          .then((res) => {
            const message = res?.data?.resetPassword?.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "success",
            });
            navigate("/login");
          })
          .catch((err) => {
            const message = err && err.message;
            if(message==="jwt expired"){
              navigate("/login", { state: "tokenExpired" })
            }else{
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            }

          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }

    
  }
  return (
    <div className="wrap wrap-height">
      <LoginHeader />
      <div className="container-fluid p-0 wrap-height">
        <div className="row m-0 wrap-height">
          <div className="col-xl-5 p-0 wrap-height create-form-left">
            <div className="login-img">
              <img
                src="../assets/images/login-new.jpg"
                className="img-fluid"
                alt="Login"
              />
              <h3 className="h3">
                You are only a few more clicks away from earning CaterCash!
              </h3>
            </div>
          </div>
          <div className="col-xl-7 p-0 wrap-height login-left ">
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className="login-box create-step-form"
            >
            <h4 className="h4">Reset Password</h4>
              <div className="textBox">
                <TextField
                  id="loginPassword"
                  label="NEW PASSWORD"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  className="textField allFeild"
                  {...register("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
              <span className="error-msg">{errors.password.message}</span>
            )}
              </div>
              <div>
                <TextField
                  id="loginPassword"
                  label="CONFIRM PASSWORD"
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  className="textField allFeild"
                  {...register("confirmPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.confirmPassword && (
              <span className="error-msg">{errors.confirmPassword.message}</span>
            )}
              </div>
              {<div className="col-12">
            <span className="subhead">
            Password must contain atleast 8 characters, including letters, numbers and special characters (@$!%*#?&)
            </span>
          </div>}
              <Button type="submit" variant="contained" className="login-btn">
                {loading?<CircularProgress color="inherit" />:"Reset"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
