import { gql } from "@apollo/client/core";

export const CURRENT_USER_DETAILS =gql`
  query CurrentUserDetails {
    currentUserDetails {
      _id
      name
      firstName
      lastName
      email
      phoneNumber
      status
      catererId
      userSrc
      imageUrl
      userType
      taxExempt
      taxCert
      disableSignUpPromo
    }
  }
`;

export const GET_LOGGED_STATUS = gql`
  query GetUserStatus {
    getUserStatus {
      message
      status
    }
  }
`;

export const GET_ONE_USER_INFO = gql`
  query GetOneUser($userId: String!) {
    getOneUser(id: $userId) {
        _id
        name
        firstName
        lastName
        regFlag
        fullName
        email
        phoneNumber
        status
        catererId
        role
        imageUrl
        adminFlg
        userSrc
        referralCode
        backupContact
        excludeCaterCashFlg
        userType
        caterCashEarned
        orderCount
        level
        taxExempt
        taxCert
        disableSignUpPromo
        createdDate
    }
  }
`;

export const GET_ONE_USER_FOR_CUSTOMER = gql`
  query GetCatererDetails($getCatererDetailsId: String, $agsType: String) {
    getCatererDetails(id: $getCatererDetailsId, agsType: $agsType) {
      _id
      status
      countryCode
      phoneNumber
      latitude
      slug
      longitude
      dlvryShutDownFlag
      cutOfTimeMng
      cutOfTimeNxtOrder
      businessName
      tagLine
      locationName
      location {
        type
        coordinates
      }
      tabValue
      deliveryFee
      deliveryFeeType
      convenienceFee
      convenienceFeeType
      customDeliveryFee
      deliveryRadius
      driverTip
      driverTipType
      preprationTime
      cancellationTime
      specialDeliveryInstn
      legalBusinessName
      deliveryFeeCater
      mandatoryTipFlg
      fax
      offersPackage
      cuisineType
      starRating
      totalRating {
        starRate
        count
      }
      imOrder
      desserts
      token
      registrationDate
      caterCash
      commission
      minOrderAmt
      catererTax
      catererZipCode
      catererCity
      catererStateShort
      deliveryOption
      option
      iAgreeFlg
      timeZone
      customDeliveryFlag
      driverTipFlag
      addEmailFlag
      cancellationPolicy
      imageUrl
      tablewareCharged
      tablewareAmount
      hasDrivers
      deliveryHrs {
        day
        flag
        openHours {
          startTime
          closeTime
        }
      }
    }
  }
`;
 export const GET_ONE_USER = gql`
  query GetOneCaterer($getOneCatererId: String, $agsType: String) {
    getOneCaterer(id: $getOneCatererId, agsType: $agsType) {
      _id
      name
      email
      role
      status
      countryCode
      phoneNumber
      additionalPhone
      latitude
      slug
      longitude
      dlvryShutDownFlag
      cutOfTimeMng
      cutOfTimeNxtOrder
      contactNumber
      businessPhone
      businessName
      tagLine
      locationName
      createdDate
      location {
        type
        coordinates
      }
      tabValue
      deliveryFee
      deliveryFeeType
      deliveryFeeTiers {
        price
        zipCodes
      }
      convenienceFee
      convenienceFeeType
      sameContact
      sameEmail
      customDeliveryFee
      deliveryRadius
      driverTip
      driverTipType
      preprationTime
      cancellationTime
      specialDeliveryInstn
      legalBusinessName
      emailForMonthlyStmt
      deliveryFeeCater
      mandatoryTipFlg
      deliveryEmail
      fax
      offersPackage
      cuisineType
      starRating
      imOrder
      desserts
      token
      registrationDate
      licence
      accountNo
      accountName
      bankName
      routingNo
      caterCash
      commission
      lastPaymentDate
      closure
      slug
      minOrderAmt
      catererTax
      catererZipCode
      catererCity
      catererStateShort
      deliveryOption
      option
      iAgreeFlg
      timeZone
      customDeliveryFlag
      driverTipFlag
      addEmailFlag
      additionalEmail
      cancellationPolicy
      tablewareCharged
      tablewareAmount
      hasDrivers
      smsEnabled
      catererUsers {
        _id
        catererId
        userId
        status
        id
        role
        userDtls {
          name
          firstName
          lastName
          email
          phoneNumber
          status
          imageUrl
          _id
          catererId
        }
      }
      imageUrl
      catererOwnerUsers {
        _id
        catererId
        userId
        role
        status
        id
        userDtls {
          _id
          name
          email
          phoneNumber
          catererId
          imageUrl
          adminFlg
        }
      }
      catererAdminUsers {
        _id
        catererId
        userId
        role
        status
        userDtls {
          role
          _id
          name
          email
          phoneNumber
          status
          catererId
          imageUrl
          adminFlg
        }
        id
      }
      deliveryHrs {
        day
        flag
        openHours {
          startTime
          closeTime
        }
      }
    }
  }
`;

export const LIST_CUISINE_TYPE = gql`
  query ListCuisineType($search: String, $skip: Int, $limit: Int, $sortField: String) {
    listCuisineType(search: $search, skip: $skip, limit: $limit, sortField: $sortField) {
      data {
        _id
        cuisineName
        order
      }
      count
    }
  }
`;

export const LIST_CATERER = gql`
  query ListCaterer($search: String, $userId: String, $skip: Int, $limit: Int, $status: String) {
    listCaterer(search: $search, userId: $userId, skip: $skip, limit: $limit, status: $status) {
      count
      data {
        name
        businessPhone
        businessName
        locationName
        commission
        caterCash
        totalOutstanding {
          completedTotal
          orderCount
          revenueTotal
          orderCountAll
        }
        location {
          type
          coordinates
        }
        _id
        catererUsers {
          _id
          catererId
          userId
          role
          status
          id
          userDtls {
            _id
            name
            firstName
            lastName
            email
            phoneNumber
          }
        }
        imageUrl
        businessName
        minOrderAmt,
        deliveryFeeType
        deliveryFeeCater
        deliveryRadius
        tagLine
        deliveryFee
        status
      }
    }
  }
`;

export const IMAGE_UPLOAD = gql`
  query imageUpload($originalFilename: String!, $path: String!) {
    imageUpload(originalFilename: $originalFilename, path: $path) {
      filename
      originalFilename
      signedUrl
      publicUrl
    }
  }
`;

export const UPLOAD_TAX_CERTIFICATE = gql`
  query uploadTaxCert($file: String!, $customerId: String!, $filename: String!, $mimetype: String!) {
    uploadTaxCert(file: $file, customerId: $customerId, filename: $filename, mimetype: $mimetype) {
      url,
      customerId
    }
  }
`;

export const GET_USER = gql`
  query GetOneUser($getOneUserId: Int) {
    getOneUser(id: $getOneUserId) {
      _id
      name
      email
      phoneNumber
      status
      catererId
      role
      imageUrl
    }
  }
`;

export const LIST_CATEGORY = gql`
  query ListCategory($search: String, $skip: Int, $limit: Int,$catererId: String, $sortField:String) {
    listCategory(search: $search, skip: $skip, limit: $limit, catererId: $catererId, sortField: $sortField) {
      data {
        _id
        categoryTitle
        status
        order
      }
      count
    }
  }
`;

export const LIST_CATEGORY_SORT = gql`
  query ListCategoryOrder($catererId: String, $sortField: String) {
    listCategoryOrder(catererId: $catererId, sortField: $sortField) {
      data {
        _id
        order
        catererId
        categoryId
        categoryDtls {
          categoryTitle
          _id
        }
      }
      count
    }
  }
`;

export const LIST_MENU = gql`
  query ListMenu($catererId: String!, $search: String, $category: String, $skip: Int, $limit: Int, $adminFlg: Boolean) {
    listMenu(catererId: $catererId, search: $search, category: $category, skip: $skip, limit: $limit, adminFlg: $adminFlg) {
      count
      data {
        _id
        itemName
        order
        categoryId
        catererId
        dietory
        itemPkgType
        itemType
        minQuantity
        price
        offerIndPkdFlg
        additionalPkdPrice
        markasPopularFlg
        itemDescription
        itemImage
        categoryDtls {
          _id
          categoryTitle
        }
        catererDtls {
          _id
          businessName
          locationName
          location {
            type
            coordinates
          }
        }
      }
      catererData {
        _id
        businessName
        locationName
        location {
          type
          coordinates
        }
      }
    }
  }
`;

export const MENU_COUNT = gql`
  query Query($catererId: String, $search: String, $category: String) {
    listMenuCount(catererId: $catererId, search: $search, category: $category)
  }
`;

export const LIST_ONE_MENU = gql`
  query GetOneMenu($getOneMenuId: String) {
    getOneMenu(id: $getOneMenuId) {
      _id
      itemName
      order
      categoryId
      catererId
      dietory
      itemPkgType
      itemType
      minQuantity
      price
      offerIndPkdFlg
      additionalPkdPrice
      markasPopularFlg
      itemDescription
      itemImage
      categoryDtls {
        _id
        categoryTitle
        status
        order
      }
      catererDtls {
        _id
        locationName
        location {
          type
          coordinates
        }
        businessName
      }
      addOnTypeList {
        _id
        addOnTypeName
        sortOrder
        menuId
        catererId
        type
        addOnTypeLimit
        addOnTypePrice
        addOnsList {
          _id
          addOnName
          sortOrder
          menuId
          addOnTypeId
          addOnType
          addOnLimit
          addOnPrice
          addOnDescription
          subAddOns {
            _id
            subSortOrder
            subAddOnName
            subAddOnPrice
          }
          catererId
        }
      }
    }
  }
`;

export const LIST_ADDON_TYPE = gql`
  query ListAddOnType($catererId: String, $menuId: String) {
    listAddOnType(catererId: $catererId, menuId: $menuId) {
      data {
        _id
        addOnTypeName
        sortOrder
        menuId
        catererId
        type
        addOnTypeLimit
        addOnsList {
          _id
          addOnName
          menuId
          sortOrder
          addOnTypeId
          catererId
          addOnType
          addOnPrice
          subAddOns {
            _id
            subAddOnName
            subAddOnPrice
            subSortOrder
          }
        }
      }
    }
  }
`;

export const LIST_CATERER_SEARCH =gql`
  query CatererSearchList($search: String, $orderType: String, $location: [Float], $deliveryDateTime: Date, $deliveryDate: Date, $deliveryTime: String, $deliveryDay: String, $headCount: Int, $dietary: String, $options: [String], $cuisines: [String], $sortOrder: String, $skip: Int, $limit: Int, $timeZone:String) {
    catererSearchList(search: $search, orderType: $orderType, location: $location, deliveryDateTime: $deliveryDateTime, deliveryDate: $deliveryDate, deliveryTime: $deliveryTime, deliveryDay: $deliveryDay, headCount: $headCount, dietary: $dietary, options: $options, cuisines: $cuisines,sortOrder: $sortOrder skip: $skip, limit: $limit, timeZone:$timeZone) {
      count
      data {
        _id
        imageUrl
        dlvryShutDownFlag
        cutOfTimeMng
        cutOfTimeNxtOrder
        businessName
        slug
        tagLine
        latitude
        longitude
        locationName
        location {
          coordinates
          type
        }
        distance
        deliveryFee
        deliveryFeeType
        convenienceFeeType
        convenienceFee
        customDeliveryFee
        customDeliveryFlag
        deliveryRadius
        driverTip
        driverTipFlag
        driverTipType
        preprationTime
        cancellationTime
        deliveryFeeCater
        mandatoryTipFlg
        offersPackage
        deliveryHrs {
          day
          flag
          openHours {
            startTime
            closeTime
          }
        }
        cuisineType
        starRating
        imOrder
        desserts
        caterCash
        minOrderAmt
        totalRating{
          count
          starRate
        }
        catererTax
        deliveryOption
        option
        menuList {
          _id
        }
      }
    }
  }
`;

export const LIST_CATERER_PROMO =gql`
  query CatererSearchList($search: String, $orderType: String, $location: [Float], $deliveryDateTime: Date, $deliveryDate: Date, $deliveryTime: String, $deliveryDay: String, $headCount: Int, $dietary: String, $options: [String], $cuisines: [String], $sortOrder: String, $skip: Int, $limit: Int, $timeZone:String) {
    catererSearchList(search: $search, orderType: $orderType, location: $location, deliveryDateTime: $deliveryDateTime, deliveryDate: $deliveryDate, deliveryTime: $deliveryTime, deliveryDay: $deliveryDay, headCount: $headCount, dietary: $dietary, options: $options, cuisines: $cuisines,sortOrder: $sortOrder skip: $skip, limit: $limit, timeZone:$timeZone) {
      count
      data {
        _id
        businessName
      }
    }
  }
`;

export const LIST_USERS = gql`
  query ListUser($userType: String, $limit: Int, $skip: Int, $search: String) {
    listUser(userType: $userType, limit: $limit, skip: $skip, search: $search) {
      data {
        _id
        name
        firstName
        orderCount
        lastName
        email
        phoneNumber
        status
        catererId
        role
        imageUrl
        adminFlg
        userSrc
        referralCode
        backupContact
        excludeCaterCashFlg
        userType
        totalSpend
      }
      count
    }
  }
`;

export const GET_ONE_CUSTOMER = gql`
  query GetOneUser($getOneUserId: String) {
    getOneUser(id: $getOneUserId) {
      _id
      name
      firstName
      lastName
      email
      phoneNumber
      userType
      imageUrl
      taxExempt
      taxCert
      disableSignUpPromo
    }
  }
`;

export const LIST_ALL_ORDERS = gql`
  query ListOrders($skip: Int, $limit: Int, $orderStatus: [String], $catererId: String, $customerId: String,$search:String,$from:Date,$to:Date, $addressId:String,$timeZone:String,$sortField:String) {
    listOrders(skip: $skip, limit: $limit, orderStatus: $orderStatus, catererId: $catererId, customerId: $customerId,search:$search, from:$from, to:$to, addressId:$addressId,timeZone:$timeZone,sortField:$sortField) {
      count
      totalAmt
      data {
        cardIcon
        cardId
        cardNo
        settlementDate
        street
        customerId
        orderStatus
        catererSlug
        pricePerPerson
        lng
        lat
        contactPerson
        city
        isPaidToCaterer
        isPaid
        cust_del
        couponApplied
        convenienceFee
        cartDetails {
          _id
          cartType
          catererId
          customerId
          cartInstruction
          itemName
          menuId
          orderId
          quantity
          cartStatus
          cartTotalPrice
          uuid
          addOns {
            _id
            addOnId
            addOnName
            addOnQuantity
            pricePerAddOn
            addOnTypeName
            subAddOns {
              _id
              pricePerSub
              subAddOnName
              subQuantity
              subtotalPrice
              subAddOnId
            }
            totalPrice
          }
        }
        caterCashAfterRefund
        commisionAmount
        netAmount
        transactionId
        updatedOn
        totalPrice
        totalPerHead
        totalAfterRefund
        tipPercentage
        salesTaxAmount
        tax
        zip
        orderNo
        orderClient
        orderDate
        locationName
        location
        isPaid
        isReOrder
        isRefunded
        instruction
        headCount
        grandTotal
        driverTip
        driverTipType
        deliveryFee
        deliveryTime
        discountAmt
        deliveryMode
        dateCancelled
        dateCompleted
        dateScheduled
        deliveryDate
        createdBy
        createdOn
        contactNo
        commision
        catererId
        caterCashTotal
        addressId
        _id
        creditCardFee
        locWithStreet
        hasDriver
        catererDetails {
          accountName
          businessName
          locationName
          catererZipCode
        }
        customerDetails {
          firstName
          lastName
          name
        }
      }
    }
  }
`;

export const GET_ONE_ORDER = gql`
  query GetOneOrder($customerId: String, $orderId: String) {
    getOneOrder(customerId: $customerId, orderId: $orderId) {
      _id
      addressId
      cardIcon
      cardId
      cardNo
      catererSlug
      uuid
      dateCancelled
      caterCashTotal
      caterCashAfterRefund
      caterCashForRefund
      orderNotes {
        _id
        createdBy
        createdOn
        note
      }
      orderLog {
        createdBy
        createdOn
        currentStatus
        message
        previousStatus
      }
      cardDetails{
        cardName
        cardLastDigits
        cardType
      }
      catererDetails{
        accountName
        deliveryFee
        deliveryFeeType
        driverTip
        driverTipFlag
        driverTipType
        businessName
        caterCash
        catererStateShort
        catererCity
        businessPhone
        catererZipCode
        catererState
        locationName
        imageUrl
        preprationTime
        location{
          coordinates
          type
        }
        longitude
        latitude
      }
      customerDetails{
        name
        phoneNumber
        email
        firstName
        lastName
        imageUrl
      }
      cartDetails {
        _id
        addOns {
          _id
          addOnId
          addOnName
          addOnQuantity
          pricePerAddOn
          addOnTypeName
          subAddOns {
            subtotalPrice
            subQuantity
            subAddOnName
            pricePerSub
            _id
            subAddOnId
          }
          totalPrice
        }
        categoryId
        minimumQuantity
        cartType
        catererId
        customerId
        cartInstruction
        itemName
        totalPricePerUnit
        pricePerUnit
        menuId
        orderId
        quantity
        isEditedOrder
        cartStatus
        cartTotalPrice
        uuid
      }
      caterCashAfterRefund
      caterCashTotal
      catererId
      city
      commision
      convenienceFee
      contactNo
      contactPerson
      couponApplied
      createdBy
      cust_del
      createdOn
      customerId
      dateCancelled
      dateCompleted
      dateScheduled
      deliveryDate
      deliveryFee
      deliveryMode
      deliveryTime
      discountAmt
      driverTip
      driverTipType
      grandTotal
      hasDriver
      headCount
      instruction
      isCaterCashDeclained
      isPaid
      isPaidToCaterer
      isReOrder
      isReferred
      isRefunded
      lat
      lng
      location
      locationName
      orderDate
      orderNo
      orderStatus
      orderClient
      orderTax
      payProfId
      placeId
      pricePerPerson
      r_transaction_id
      reauthTransId
      refererReward
      refundAmount
      refundDate
      refundText
      refundTransactionId
      refundType
      salesTaxAmount,
      salesTaxPercentage
      settlementDate
      shipToId
      street
      state
      suit
      tax
      tablewareCharged
      tablewareAmount
      tablewareDeclined
      tablewareTotal
      tipPercentage
      isEditedOrder
      totalAfterRefund
      totalPerHead
      totalPrice
      transactionId
      updatedBy
      updatedOn
      zip
      catererDetails {
        businessName
      }
    }
  }
`;

export const LIST_ADDRESS = gql`
  query ListAddress($search: String, $skip: Int, $limit: Int,$sortField:String,$customerId:String!) {
    listAddress(search: $search, skip: $skip, limit: $limit,sortField:$sortField,customerId:$customerId) {
      data {
        _id
        city
        latitude
        longitude
        street
        zipcode
        location
        locationName
        geolocation{
          lat
          long
        }
        floor
        defaultAddress
        customerState
        customerId
        existingOrderDate
        orderDate
        customerCity
        customerZip
        contactName
        number
        Phone
        createdOn
        instruction
      }
      count
    }
  }
`;

export const LIST_CARDS =gql`
  query ListCard($customerId: String) {
    listCard(customerId: $customerId) {
      data {
        _id
        authorizeId
        cardExpr
        cardImg
        cardLastDigits
        cardName
        cardType
        locationName
        additionalEmail
        addEmailChk
        customerId
        nickName
        defaultCard
        billingZipCode
      }
    }
  }
`;

export const GET_ONE_CARD =gql`
  query GetOneCard($getOneCardId: String) {
    getOneCard(id: $getOneCardId) {
      _id
      authorizeId
      cardExpr
      cardImg
      cardLastDigits
      cardName
      customerId
      defaultCard
      nickName
    }
  }
`;

export const CHECK_AVL_DATE= gql`
  query ChkCatererAvbl($data: createOrderInput) {
    chkCatererAvbl(data: $data) {
      message
      status
      serverTime
    }
  }
`;

export const ORDER_SUMMERY =gql`
  query OrderSummaryHeader($userType: String, $userId: String, $status: String) {
    orderSummaryHeader(userType: $userType, userId: $userId, status: $status) {
      avgTotalAmt
      cancelledOrders
      completedOrders
      newOrders
      scheduledOrders
    }
  }
`;

export const LIST_PROMO_CODE= gql`
  query ListPromoCodes($data: promoCodeInput) {
    listPromoCodes(data: $data) {
      promoCodes{
      _id
      catererId
      coupenCode
      codeStatus
      customerLimit
      totalUsage
      discountAmt
      expDate
      }
      count
    }
  }
`;

export const PRINT_INVOICE = gql`
  query PrintOrderInvoice($orderId: String, $timeZone:String) {
    printOrderInvoice(orderId: $orderId, timeZone:$timeZone) {
      pdfUrl
    }
  }
`;

export const LIST_CATER_CASH= gql`
  query ListCaterCash($search: String, $skip: Int, $limit: Int, $customerId: String, $sortField: String, $caterCashType: String, $tabValue: String) {
    listCaterCash(search: $search, skip: $skip, limit: $limit, customerId: $customerId, sortField: $sortField, caterCashType: $caterCashType, tabValue: $tabValue) {
      count
      data {
        _id
        paidOn
        voucherCode
        caterCashStatus
        amount
        caterCashType
        isReferral
        customerName
        caterCashSrc
        catererName
        orderTotalAmt
        orderId
        orderNo
        customerId
        giftNo
      }
    }
  }
`;

export const GET_SALES_TAX = gql`
  query GetSalesTax($city: String!, $zip: String!, $catererStateShort: String, $customerId: String) {
    getSalesTax(city: $city, zip: $zip, catererStateShort: $catererStateShort, customerId: $customerId) {
      salesTax
      city
      zip
      customerId
    }
  }
`;

export const TOTLA_CATERCASH = gql`
  query ListCaterCashTotal($customerId: String, $caterCashType: String, $search: String) {
    listCaterCashTotal(customerId: $customerId, caterCashType: $caterCashType, search: $search) {
      amzBalance
      totEarned
      totGift
      totRedeemed
      totRefunded
      totExpired
    }
  }
`;

export const LIST_PAYMENT_CATERER = gql`
  query ListPaymentCaterers($search: String, $skip: Int, $limit: Int, $status: String, $userId:String) {
    listPaymentCaterers(search: $search, skip: $skip, limit: $limit,status: $status, userId: $userId) {
      data {
        _id
        businessName
        catererUsers{
          _id
          catererId
          userDtls{
            firstName
            lastName
            name
            phoneNumber
          }
        }
        locationName
        imageUrl
        contactNumber
        firstName
        lastName
        totalOutstanding{
          completedTotal
          settledTotal
        }
      }
      count
    }
  }
`;

export const APPLY_PROMOCODE = gql`
  query ApplyPromoCode($promoCode: String!, $orderId: String,$catererId:ID) {
    applyPromoCode(promoCode: $promoCode, orderId: $orderId, catererId:$catererId) {
      _id
      catererId
      codeStatus
      coupenCode
      customerLimit
      discountAmt
      expDate
      grandTotal
      totalPerHead
      timeZone
      orderId
      message
    }
  }
`;

export const LIST_REVIEW= gql`
  query ListReviews($customerId: ID, $catererId: ID, $skip: Int, $limit: Int, $adminFlag: Boolean!, $search: String) {
    listReviews(customerId: $customerId, catererId: $catererId, skip: $skip, limit: $limit, adminFlag: $adminFlag, search: $search) {
      count
      reviewsList {
        _id
        catererName
        comment
        customerFName
        reviewDate
        star
        catererId
        customerId
        timeZone
        approvalStatus
      }
    }
  }
`;

export const LIST_REVIEW_CUSTOMER= gql`
  query ListReviewsCaterer($adminFlag: Boolean!, $search: String, $customerId: ID, $catererId: ID, $skip: Int, $limit: Int) {
    listReviewsCaterer(adminFlag: $adminFlag, search: $search, customerId: $customerId, catererId: $catererId, skip: $skip, limit: $limit) {
      count
      reviewsList {
        catererName
        comment
        customerFName
        reviewDate
        star
        timeZone
        approvalStatus
      }
    }
  }
`;

export const LIST_REVIEW_ADMIN= gql`
  query ListReviews($customerId: ID, $catererId: ID, $skip: Int, $limit: Int, $adminFlag: Boolean!, $search: String) {
    listReviews(customerId: $customerId, catererId: $catererId, skip: $skip, limit: $limit, adminFlag: $adminFlag, search: $search) {
      count
      reviewsList {
        _id
        catererName
        comment
        customerFName
        customerLName
        customerName
        reviewDate
        star
        catererId
        customerId
        timeZone
        approvalStatus
      }
    }
  }
`;

export const LIST_REFER_FRIENDS =gql`
  query ListReferFriends($search: String, $skip: Int, $limit: Int) {
    listReferFriends(search: $search, skip: $skip, limit: $limit) {
      count
      friendslist {
        _id
        action
        customerId
        isFirstOrder
        isRegistered
        refere
        referedEmail
        referrer
      }
    }
  }
`;

export const LIST_SETTINGS = gql`
  query ListSettings($skip: Int, $limit: Int) {
    listSettings(skip: $skip, limit: $limit) {
      data{
        _id
        description
        order
        title
        value

      }
      count
    }
  }
`;

export const DASHBOARD_TOTAL = gql`
  query GetTotalsForDashboard($userId: String) {
    getTotalsForDashboard(userId: $userId) {
      caterCashBalance
      caterCashEarned
      commisionEarned
      completedOrders
      totalCaterers
      totalCustomers
    }
  }
`;

export const CATER_CASH_BALANCE= gql`
  query GetCaterCashBalance($userId: String) {
    getCaterCashBalance(userId: $userId) {
      caterCashBalance
    }
  }
`;

export const SEARCH_ADDRESS = gql`
  query searchAddress($customerId: ID!,$keyWord:String) {
    searchAddress(customerId: $customerId,keyWord:$keyWord ) {
      _id
      city
      latitude
      longitude
      street
      zipcode
      location
      locationName
      geolocation{
        lat
        long
      }
      floor
      defaultAddress
      customerState
      customerId
      customerCity
      customerZip
      contactName
      number
      Phone
      createdOn
      instruction
    }
  }
`;

export const GET_DELIVERY_FEE = gql`query GetDeliveryFee($orderType: String, $totalCost: Float!, $catererId: String!, $zipCode: String) {
  getDeliveryFee(orderType: $orderType, totalCost: $totalCost, catererId: $catererId, zipCode: $zipCode) {
    deliveryFee
  }
}`;

export const GET_CONVENIENCE_FEE = gql`
  query GetConvenienceFee($totalCost: Float!) {
    getConvenienceFee(totalCost: $totalCost) {
      feePercentage
      feeTotal
      description
    }
  }
`;