import { formatter } from "../../../../Modules/Common/commonUtils";
import { ORDER_DELIVERY_MODE } from "@constants/order.constants";
import { isEmpty } from "lodash";

interface CartErrorValidationParams {
  address: string;
  zip: string;
  headCount: number;
  isAvailable: "date" | "address" | true;
  cartCost: number;
  minOrderAmount: number;
  date: Date;
  user: {
    data?: {
      currentUserDetails?: {
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
      };
      createUserIdentity?: {
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
      };
      login?: {
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
      }
    };
  };
  auth: any;
}

export function validateCartError({
  address,
  zip,
  isAvailable,
  headCount,
  cartCost,
  minOrderAmount,
  date,
  user,
  auth,
}: CartErrorValidationParams) {
  const firstName = user?.data?.currentUserDetails?.firstName || user?.data?.createUserIdentity?.firstName || user?.data?.login?.firstName;
  const lastName = user?.data?.currentUserDetails?.lastName || user?.data?.createUserIdentity?.lastName || user?.data?.login?.lastName;
  const phoneNumb = user?.data?.currentUserDetails?.phoneNumber || user?.data?.createUserIdentity?.phoneNumber || user?.data?.login?.phoneNumber;
  const invalidFirstName = isEmpty(firstName);
  const invalidLastName = isEmpty(lastName);
  const inValidPhoneNumber = isEmpty(phoneNumb);
  const hasAuth = auth?.auth;

  if (!address) {
    return {
      title: "Choose address",
      description: "Before you move further, please select your address for the delivery.",
    };
  }
  if (!zip) {
    return {
      title: "Choose full address",
      description: "Before you move further, please make sure that your full address is correct.",
    };
  }
  if (!headCount) {
    return {
      title: "Choose order people amount",
      description: "Before you move further, please enter people amount for the delivery.",
    };
  }

  if (hasAuth && (invalidFirstName || invalidLastName)) {
    return {
      title: "Choose your name",
      description: "Before you move further, please go to your profile and add your name.",
    };
  }

  if (hasAuth && inValidPhoneNumber) {
    return {
      title: "Choose your phone number",
      description: "Before you move further, please go to your profile and add your phone number.",
    };
  }

  if (!date) {
    return {
      title: "Choose date and time",
      description: "Before you move further, please select the date and time for the delivery.",
    };
  }

  if (isAvailable === "address") {
    return {
      title: "Caterer is unavailable",
      description: "Please select different address or choose another caterer",
    };
  }

  if (isAvailable === "date") {
    return {
      title: "Caterer is unavailable",
      description: "Please select different time or choose another caterer",
    };
  }

  if (cartCost < minOrderAmount) {
    return {
      title: "Minimum Order Not Met",
      description: `Caterer has a food minimum of ${formatter.format(minOrderAmount)}.`,
    };
  }
}

interface CartWarningsValidationParams {
  headCount: number;
  cartItemsQuantity: number;
  isTableWareCharged: boolean;
  tip: number;
  isTipMandatory: boolean;
  deliveryMode: string | null;
}

export function validateCartWarnings({
  headCount,
  cartItemsQuantity,
  isTableWareCharged,
  tip,
  isTipMandatory,
  deliveryMode,
}: CartWarningsValidationParams) {
  const warnings = [];
  if (headCount < cartItemsQuantity) {
    warnings.push({
      title: "Headcount",
      description: "Your headcount is less than the items in your cart.",
    });
  }

  if (headCount === 1 && isTableWareCharged) {
    warnings.push({
      title: "Headcount",
      description: `This caterer charges for tableware and your headcount is 1. Do you need to update headcount?`,
    });
  }

  if (!isTipMandatory && tip === 0 && deliveryMode !== ORDER_DELIVERY_MODE.pickup) {
    warnings.push({
      title: "Tip",
      description:
        "It appears that a tip hasn't been added yet. Would you like to skip this step or add a tip now?",
    });
  }

  return warnings;
}
