import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, CircularProgress } from "@mui/material";

import LoginHeader from "../../Modules/Login/LoginHeader";
import { ReviewOrderCart } from "./review-order-cart";
import { PriceView } from "./price-view";
import { PaymentCard } from "@components/organisms/payment-card";
import Footer from "../../Modules/Common/footer";

import { useReviewOrderState } from "./review-order.state";
import { InputForm } from "@components/molecules/input-form";
import { SelectForm } from "@components/select-form";
import { DatePickerForm } from "@components/datepicker-form";
import { LocationSearchForm } from "@components/molecules/location-search-form";
import { PhoneForm } from "@components/molecules/phone-form";
import { CheckboxForm } from "@components/checkbox-form";
import { ORDER_DELIVERY_MODE } from "@constants/order.constants";

export function ReviewOrder() {
  const {
    isEdit,
    control,
    cardList,
    isSubmitLoading,
    orderProps,
    card,
    tip,
    order,
    userType,
    taxExempt,
    onUpdateForm,
    onUpdateDeliveryMode,
    onSubmit,
    onSelectLocation,
  } = useReviewOrderState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <LoginHeader />
      <div className="common-view-box-wrap review-order">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <Link
                to={`/caterers/${order.catererSlug}${isEdit ? "?edit=true" : ""}`}
                className="back-link">{`Back to ${order.catererName}`}</Link>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="common-view-box-title">
                <div>
                  <h5>Review Order</h5>
                  <small>Please review your order</small>
                </div>
              </div>
              <div className="common-view-box">
                <div className="row">
                  <h6>Confirm Date & Time</h6>
                  <div className="col-md-6 review-order-datepicker textBox">
                    <div className="textField">
                      <DatePickerForm
                        control={control}
                        name="date"
                        rules={{
                          required: "Date & Time are required",
                        }}
                        label="Select a date and time"
                        onUpdate={onUpdateForm}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 textBox">
                    <SelectForm
                      id="orderType"
                      label="ORDER TYPE*"
                      variant="outlined"
                      className="allFeild"
                      control={control}
                      name="orderType"
                      rules={{
                        required: "Order type is required",
                      }}
                      options={[
                        {
                          value: ORDER_DELIVERY_MODE.delivery,
                          label: ORDER_DELIVERY_MODE.delivery,
                        },
                        { value: ORDER_DELIVERY_MODE.pickup, label: ORDER_DELIVERY_MODE.pickup },
                      ]}
                      onUpdate={onUpdateDeliveryMode}
                    />
                  </div>
                </div>
                <div className="row">
                  <h6>Confirm Delivery Address</h6>
                  <div className="col-md-9 textBox">
                    <div className="textField ">
                      <LocationSearchForm
                        label="STREET ADDRESS*"
                        control={control}
                        name="street"
                        rules={{
                          required: "Street address is required",
                        }}
                        onChangeLocationOptions={onSelectLocation}
                        onUpdate={onUpdateForm}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 textBox">
                    <InputForm
                      id="zip"
                      label="ZIP CODE"
                      variant="outlined"
                      className="textField allFeild"
                      control={control}
                      disabled={true}
                      name="zip"
                      rules={{
                        required: "Zip Code is required",
                      }}
                    />
                  </div>
                  <div className="col-md-6 textBox">
                    <InputForm
                      id="suit"
                      label="SUITE/FLOOR"
                      variant="outlined"
                      className="textField allFeild"
                      control={control}
                      name="suit"
                      rules={{
                        maxLength: {
                          value: 30,
                          message: "Suite/Floor should be less than 30 characters",
                        },
                      }}
                      onUpdate={onUpdateForm}
                    />
                  </div>

                  <div className="col-md-6 textBox">
                    <InputForm
                      id="location"
                      label={"LOCATION NAME*"}
                      variant="outlined"
                      className="textField allFeild"
                      control={control}
                      name="location"
                      rules={{
                        required: "Location name is required",
                        maxLength: {
                          value: 50,
                          message: "Location name should be less than 50 characters",
                        },
                      }}
                      onUpdate={onUpdateForm}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 textBox">
                    <InputForm
                      id="contactName"
                      label="BUSINESS CONTACT NAME*"
                      variant="outlined"
                      className="textField allFeild"
                      control={control}
                      name="contactName"
                      rules={{
                        required: "Contact name is required",
                        maxLength: {
                          value: 30,
                          message: "Contact name should be less than 30 characters",
                        },
                        validate: (value) => value.trim() !== "" || "Contact name is required",
                      }}
                      onUpdate={onUpdateForm}
                    />
                  </div>
                  <div className="col-md-6 textBox">
                    <PhoneForm
                      id="contactPhone"
                      label="BUSINESS PHONE NUMBER*"
                      variant="outlined"
                      className="textField allFeild"
                      control={control}
                      rules={{
                        required: "Phone number is required",
                      }}
                      name="contactPhone"
                      onUpdate={onUpdateForm}
                    />
                  </div>
                  <div className="col-md-12 textBox">
                    <InputForm
                      id="instruction"
                      label="ADD DELIVERY INSTRUCTIONS"
                      variant="outlined"
                      className="textField allFeild"
                      multiline
                      rows={4}
                      control={control}
                      name="instruction"
                      rules={{
                        maxLength: {
                          value: 500,
                          message: "Delivery instructions should be less than 500 characters",
                        },
                      }}
                      onUpdate={onUpdateForm}
                    />
                  </div>
                </div>
                {userType !== "Admin" && (
                  <div className="col-md-12">
                    <div className="textBox create-account-condition">
                      <CheckboxForm
                        control={control}
                        name="isSaveAddress"
                        label="Save to Address Book"
                        rules={{}}
                      />
                    </div>
                  </div>
                )}
                <ReviewOrderCart
                  cartDetails={order.cartDetails}
                  catererSlug={order.catererSlug}
                  isEdit={isEdit}
                />
                {userType !== "Admin" && (
                  <>
                    {/* TODO: split component to reduce props amount */}
                    {/* TODO: add/remove tableware */}
                    <PriceView
                      cartCost={orderProps.priceInfo.cartCost}
                      tablewareCost={orderProps.priceInfo.tableWare}
                      deliveryFee={orderProps.priceInfo.deliveryFee}
                      salesTaxPercent={orderProps.salesTax.percent}
                      salesTaxAmount={orderProps.salesTax.amount}
                      isDelivery={orderProps.orderType !== ORDER_DELIVERY_MODE.pickup}
                      isMandatoryTip={orderProps.priceInfo.isMandatoryTip}
                      driverTip={tip.value}
                      percentageTip={tip.percentageTip}
                      onChangePercentageTip={tip.onChangePercentageTip}
                      onChangeCustomTip={tip.onChangeCustomTip}
                      headCount={orderProps.priceInfo.headCount}
                      totalCost={orderProps.priceInfo.totalCost}
                      caterCash={orderProps.priceInfo.caterCash}
                      taxExempt={taxExempt}
                      convenienceFee={orderProps.priceInfo.convenienceFee}
                    />
                    <PaymentCard
                      orderData={order}
                      reviewOrderScreen={true}
                      listCard={cardList}
                      checkedCard={card.value}
                      setCheckedCard={card.onChangeCard}
                    />
                  </>
                )}
                <Button
                  className="confirm-place-btn"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={isSubmitLoading}>
                  <img
                    src="../assets/images/icon-cart.svg"
                    className="img-fluid"
                    alt="Confirm and Place Order"
                  />
                  {isSubmitLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : isEdit ? (
                    "  Confirm Edited Order"
                  ) : (
                    "  Confirm and Place Order"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
