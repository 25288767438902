import React, { useEffect } from "react";
import LoginHeader from "../../Login/LoginHeader";
import Footer from "../../Common/footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const HubSpotForm = () => {
  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "47212632",
        formId: "e76b8b89-fe3a-4ff4-b227-f250c47acfc3",
        target: "#hubspot-form"
      });
    }
  }, []);

  return <div id="hubspot-form"></div>;
};

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>CaterPlace - Corporate Catering</title>
        <meta name="Keywords" />
        <meta name="description" content="Email Support@caterplace.com" />
      </Helmet>
      <LoginHeader />
      <section className="contact">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-img" style={{height: '100vh'}}>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form-wrap">
                <div className="contact-form">
                  <p>
                    For Help/Inquiries please check our{" "}
                    <Link to={"/faq"}>FAQ's</Link>.
                  </p>
                  <p>
                    You can also reach us at{" "}
                    <a href="mailto:support@caterplace.com">
                      support@caterplace.com
                    </a>{" "}
                    or to text or call please contact :{" "}
                    <a href="tel:646-535-4875">646-535-4875.</a>
                  </p>
                  <h5 className="h5">Contact Us</h5>
                  <HubSpotForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
